const goToMapCoord = function(map, coords, zoom = 18) {
    let xCoords = parseFloat(coords[0]);
        
    let yCoords = parseFloat(coords[1]);
    
    map.setCenter([xCoords, yCoords], zoom, {
        checkZoomRange: false,
        duration: 0,
    });
};

const filtersMapObjects = function() {
    partnersMap.geoObjects.removeAll();
    
    geoObjects = [];
    
    let productList = document.querySelectorAll('[data-map-item]');

    for(let i = 0; i < productList.length; i++) {
        
        if (productList[i].dataset.coordinates) {

            let coordinates = productList[i].dataset.coordinates.split(',');
            let title = productList[i].dataset.title;
            let city = productList[i].querySelector('[data-map-item-city]');
            let address = productList[i].querySelector('[data-map-item-address]');
            let tel = productList[i].querySelector('[data-map-item-tel]');
            let link = productList[i].querySelector('[data-map-item-link]');
            
            let ballonContent = '';
            let ballonFooter = '';
            
            if (city) ballonContent = `<b>Город:</b><br><span>${city.textContent}</span><br><br>`;
            if (address) ballonContent = `${ballonContent} <b>Адрес:</b><br><span>${address.textContent}</span><br><br>`;
            if (tel) ballonContent = `${ballonContent} <b>Телефон:</b><br><span>${tel.textContent}</span><br>`;
            
            if (link) ballonFooter = `<a class="balloon-read-more" target="_blank" href="${ link.href }">Узнать детали <b>&gt;</b></a>`;
            
            let placemark = new ymaps.Placemark([coordinates[0],coordinates[1]], {
                balloonContentHeader: `<b>${ title }</b>`,
                balloonContentBody: ballonContent,
                balloonContentFooter: ballonFooter,
                hintContent: title,
            },
            {
                preset: 'islands#redCircleDotIcon',
            });
            
            geoObjects.push(placemark);
        }
        
    }
    
    clusterer.options.set({
        gridSize: 80,
        clusterDisableClickZoom: true
    });
    
    clusterer.removeAll();
    clusterer.add(geoObjects);
    partnersMap.geoObjects.add(clusterer);
    
    let mapCenter = clusterer.getBounds();
    if (mapCenter !== null) {
        partnersMap.setBounds(clusterer.getBounds(), {
            checkZoomRange: true
        });   
    }
};

if (document.querySelector('#partners_map')) {
    var partnersMap = null;

    ymaps.ready(function() {
    
        partnersMap = new ymaps.Map("partners_map", {
            center: [54.2909821, 48.1007516],
            zoom: 11,
            controls: []
        },
        {
            suppressMapOpenBlock: true,
        }),
        clusterer = new ymaps.Clusterer({
            preset: 'islands#invertedRedClusterIcons',
            groupByCoordinates: false,
            clusterDisableClickZoom: true,
            clusterHideIconOnBalloonOpen: false,
            geoObjectHideIconOnBalloonOpen: false
        }),
        
        partnersMap.controls.add('zoomControl');
        
        filtersMapObjects();
        
        document.addEventListener('click', (event) => {
            let target = event.target;
            
            if (target.closest('[data-tab-select]')) {
                setTimeout(function () {
                    let mapCenter = clusterer.getBounds();
                    if (mapCenter !== null) {
                        partnersMap.setBounds(clusterer.getBounds(), {
                            checkZoomRange: true
                        });   
                    }
                }, 200);
            }
        })
    });
    
    document.addEventListener("DOMContentLoaded", function(event) {
        $(document).on('mse2_load', function(e, data_result) {
            filtersMapObjects();
        });
        
        document.addEventListener('click', function(event) {
            const target = event.target;
           
            if (target.closest('[data-go-coords]')) {
                let coords = target.closest('[data-go-coords]').getAttribute('data-go-coords').split(",");
    
                goToMapCoord(partnersMap, coords);
            }
        });
    
    });
}