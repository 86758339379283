$(document).ready(function () {

  // address top
  $("#adress .piter").hide();
  $("#adress .moskow").click(function () {
    $(this).next(".piter").slideToggle("slow").siblings(".piter:visible").slideUp("slow");
    $("#adress .moskow").toggleClass('menu-open');
  });
  // END address top

$("input[type='tel'], input[name='phone']").mask("+7(999)999-99-99");

  // main page top slider
  $(".animated").each(function (i, elem) {
    if ($(this).parents().hasClass("div-slider"))
      $(this).addClass($(this).attr("anim"));
  });
  $(".anythingControls a").click(function () {
    clearInterval(myTimer);
    myTimer = setInterval(timer, 5000);
    $(".animated").each(function (i, elem) {
      if ($(this).parents().hasClass("div-slider"))
        $(this).removeClass($(this).attr("anim"));
    });
    setTimeout(function () {
      $(".animated").each(function (i, elem) {
        if ($(this).parents().hasClass("div-slider"))
          $(this).addClass($(this).attr("anim"));
      });
    }, 200);

  });

  // filters dropdown
  $('.filters .slct').click(function () {
    $(this).siblings('.slct_dropdown').toggleClass('active');
  });
  $('.filters .slct_dropdown li').click(function () {
    console.log($(this).text());
    $(this).parents('.slct_dropdown').siblings('.slct').text($(this).text());
    $(this).parents('.slct_dropdown').removeClass('active');
  });
  $('.filters .series .slct_dropdown li, .filters .model .slct_dropdown li').addClass('hidden');

  $('.select.category li').click(function () {
    $('.select.series li').addClass('hidden');
    $('.select.model li').addClass('hidden');
    $('.select.series .slct').text('Серия продукта:');
    $('.select.model .slct').text('Модель продукта:');
    $('.select.series li[data-category="' + $(this).data('category') + '"]').removeClass('hidden');
    $('#items').text('Не выбрана модель продукта...');
  });
  $('.select.series li').click(function () {
    $('.select.model li').addClass('hidden');
    $('.select.model .slct').text('Модель продукта:');
    $('.select.model li[data-series="' + $(this).data('series') + '"]').removeClass('hidden');
    $('#items').text('Не выбрана модель продукта...');
  });

  // getting downloadable
  $('.js-downloadable').click(function () {
    const data = {
      action: 'get_downloadable',
      product_id: $(this).data('product'),
    };

    $.post(document.location.href, data, function (response) {
      if (response.success) {
        $('#items').html(response.data);
      } else {
        $('#items').html(response.data);
      }
    }, 'json');

  });

  function timer() {
    var s;
    var cur;
    $(".anythingControls a").each(function (i, elem) {
      if ($(this).hasClass("cur"))
        cur = i + 1;
      $(this).addClass("ccc" + i);
      s = i - 1;
    });
    if (s == cur - 1)
      $(".anythingControls a.ccc0").click();
    else
      $(".anythingControls a.ccc" + cur).click();
  }

  var myTimer;
  myTimer = setInterval(timer, 5000);

  var myTimer1;
  myTimer1 = setInterval(function () {
    $(".block_num .n2").html($("#slider .panel").length)
    if ($(".panel").hasClass("activePage"))
      $(".block_num .n1").html($("#slider .activePage").index() + 1);
  }, 100);
  // END main page top slider

  // to top button
  var top_show = 150; // В каком положении полосы прокрутки начинать показ кнопки "Наверх"
  var delay = 1000; // Задержка прокрутки
  $(window).scroll(function () { // При прокрутке попадаем в эту функцию
    /* В зависимости от положения полосы прокрукти и значения top_show, скрываем или открываем кнопку "Наверх" */
    if ($(this).scrollTop() > top_show) {
      $('#backtop').fadeIn();
    } else {
      $('#backtop').fadeOut();
    }
  });
  $('#backtop').click(function () { // При клике по кнопке "Наверх" попадаем в эту функцию
    /* Плавная прокрутка наверх */
    $('body, html').animate({
      scrollTop: 0
    }, delay);
  });
  
  setTimeout(function(){
    
    if (document.querySelector('#backtop')) {
        document.body.append(document.querySelector('#backtop'));   
    }
  }, 1000);
  // END to top button

  // smooth scroll
  $('a').smoothScroll({
    speed: 1000,
    easing: 'easeInOutCubic'
  });
  $('.showOlderChanges').on('click', function (e) {
    $('.changelog .old').slideDown('slow');
    $(this).fadeOut();
    e.preventDefault();
  });
  // END smooth scroll

  // service centres
  // $("#list_town ul").hide();
  // $("#list_town .list_city").click(function() {
  //     $(this).next("ul").slideToggle("slow").siblings("ul:visible").slideUp("slow");
  // $("#list_town .list_city").toggleClass('menu-open');
  // END service centers

  //
  $('select[name="parent"], select[name="partner_status"], select[name="pagetitle"]').select2({
    minimumResultsForSearch: -1,
    width: '290px',
  });

  //
  $("#list_town ul").hide();
  $("#list_town .list_city").click(function () {
    $(this).next("ul").slideToggle("slow").siblings("ul:visible").slideUp("slow");
    $("#list_town .list_city").toggleClass('menu-open');
  });


document.addEventListener("click", (e) => {
  if (e.target.classList.contains("header-search__icon")) {
    const button = e.target.parentNode;
    const parent = button.parentNode;
    const searchBlock = parent.querySelector(".search-mobile__block");
    searchBlock.classList.add("active");
  }
  if (e.target.classList.contains("search-mobile__btn")) {
    const button = e.target.parentNode;
    const parent = button.parentNode;
    const searchBlock = parent.querySelector(".search-mobile__block");
    searchBlock.classList.add("active");
  }
});

document.addEventListener("click", (e) => {
  if (e.target.classList.contains("nav-mobile__close-btn")) {
    const button = e.target.parentNode;
    const parent = button.parentNode;
    parent.classList.remove("active");
  }
});


  document.addEventListener("click", (e) => {
    if (
      e.target.classList.contains("faq-item__title-js") ||
      e.target.classList.contains("faq-item__title-sign")
    ) {
      const parent = e.target.parentNode;
      const text = parent.querySelector(".faq-item__text-js");
      if (parent.classList.contains("active")) {
        parent.classList.remove("active");

      } else {
        parent.classList.add("active");
  
      }
    }

    if (e.target.classList.contains("faq-item__title-text")) {
      const title = e.target.parentNode;
      const parent = title.parentNode;
      const text = parent.querySelector(".faq-item__text-js");
      if (parent.classList.contains("active")) {
        parent.classList.remove("active");
      } else {
        parent.classList.add("active");
      }
    }
  });
  
  
  const bannerOwl = $('.banner__owlcarousel')
  bannerOwl.owlCarousel({
    items: 1,
    goToFirst: true,
    pagination: true,
    itemsDesktop : [1920,1],
    itemsDesktopSmall : [1440,1],
    itemsTablet: [1024,1],
    itemsMobile : [768,1],
  })
  
  const catalogOwl = $('.catalog__owlcarousel')
  catalogOwl.owlCarousel({
    items: 3,
    goToFirst: true,
    itemsDesktop : [1920,3],
    itemsDesktopSmall : [1440,3],
    itemsTablet: [1024,2],
    itemsMobile : [767,1],
    pagination: true
  })
  
  const shopsOwl = $('.shops__owlcarousel')
  shopsOwl.owlCarousel({
    items: 6,
    goToFirst: true,
    itemsDesktop : [1920,6],
    itemsDesktopSmall : [1440,4],
    itemsTablet: [1024,3],
    itemsMobile : [767,2],
    pagination: true
  })

  const bestsellersOwl = $('.bestsellers__owlcarousel')
  bestsellersOwl.owlCarousel({
    items: 5,
    goToFirst: true,
    nav: false,
    itemsDesktop : [1920,5],
    itemsDesktopSmall : [1440,3],
    itemsTablet: [1024,2],
    itemsMobile : [767,1],
    pagination: true
  })

  const newsOwl = $('.news-block__owlcarousel')
  newsOwl.owlCarousel({
    items: 4,
    goToFirst: true,
    itemsDesktop : [1920,4],
    itemsDesktopSmall : [1440,3],
    itemsTablet: [1024,1],
    itemsMobile : [767,1],
    pagination: true,
  })

document.addEventListener("click", (e) => {
    if (e.target.classList.contains("slide-down")) {
      const parent = e.target.parentNode;
      const accord = parent.querySelector('.accord')
      accord.style.display = 'block'
    }
    if (e.target.classList.contains("slide-up")) {
      const accord = e.target.parentNode;
      accord.style.display = 'none'
    }
  }, true);

const menuItems = document.querySelectorAll('.header-n-nav__link')

  document.addEventListener('mouseover', (e)=>{
    const menuItem = e.target
    
    if(menuItem.closest('.header-n-nav__link')){
      if(menuItem.classList.contains('header-n-nav__link')){
        menuItem.classList.add('active')
      }else{
        const parent = menuItem.parentNode
        parent.classList.add('active')
      }
      
    }else{
      menuItems.forEach(e=>e.classList.remove('active'))
    }
  })

  
  
  

});

const glightbox = GLightbox();

document.addEventListener('click', (event) => {
    let target = event.target;
    
    if (target.closest('.product-gallery__image')) {
        glightbox.open();
    }
});