document.addEventListener("DOMContentLoaded", function() {
    const initTabs = function() {
        
        const firstTabActive = function() {
            if (document.querySelector('[data-tab]')) {
                let tabGroupArr = [];
        
                document.querySelectorAll('[data-tab-group]').forEach(function(item){
                    tabGroupArr.push(item.getAttribute('data-tab-group'));
                });
                
                tabGroupArr = Array.from(new Set(tabGroupArr))
                
                tabGroupArr.forEach(function(tabGroup){
                    document.querySelector(`[data-tab-group=${tabGroup}][data-tab-select]:not([data-tab-outer-elem=true])`).classList.add('active');
                    document.querySelector(`[data-tab-group=${tabGroup}][data-tab]`).classList.add('active');
                });
            }
        }
        
        const checkTabs = function(tab, tabsGroup) {
        
            document.querySelectorAll(`[data-tab-group=${tabsGroup}]`).forEach(function(item){
                item.classList.remove('active');
            });
            
            document.querySelector(`[data-tab-group=${tabsGroup}][data-tab=${tab}]`).classList.add('active');
            document.querySelector(`[data-tab-group=${tabsGroup}][data-tab-select=${tab}]:not([data-tab-outer-elem=true])`).classList.add('active');
            
        }
        
        //firstTabActive();
        
        document.addEventListener('click', function(event){
            const target = event.target;
            
            if (target.closest('[data-tab-select]') && target.closest('[data-tab-group]')) {
                
                target.closest('[data-tab-outer-elem]') ? '' :  event.preventDefault();
                
                const tab = target.closest('[data-tab-select]');
                
                checkTabs(tab.getAttribute('data-tab-select'), tab.getAttribute('data-tab-group'));
            }
        });  
    };
    
    initTabs();
    
});